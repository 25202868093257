import * as Sentry from '@sentry/react'
import 'whatwg-fetch'

export const onClientEntry = () => {
  Sentry.init({
    environment: process.env.NODE_ENV || 'development',
    // Netlify - https://docs.netlify.com/configure-builds/environment-variables/#build-metadata
    release: __SENTRY_RELEASE__, // eslint-disable-line no-undef
    dsn: __SENTRY_DSN__, // eslint-disable-line no-undef
    enabled: process.env.NODE_ENV === 'production',
  })
  window.Sentry = Sentry
}
